import React from 'react'

import classNames from 'classnames'
import {
  useLocation, useNavigate 
} from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import styled from 'styled-components'

import { getBasketProducts } from './selectors'

export const OrderBtn = ({
  className, text, onClick, disabled 
}) => {
  return (
    <OrderBtnBox className={classNames(className, { disabled })}>
      <Button onClick={onClick}>
        {text}
      </Button>
    </OrderBtnBox>
  )
}

export const BasketBtn = ({ className = '' }) => {
  const basketProducts = getBasketProducts()
  const location = useLocation()
  const navigate = useNavigate()

  const amount = basketProducts.reduce((r, p) => r += p.price * (p.count || 1), 0)

  const handleClick = () => {
    navigate(ROUTES.basket)
  }

  if (location.pathname === '/basket' || !amount) {
    return null
  }

  return (
    <OrderBtn
      className={className}
      text={`Корзина - ${amount} ₽`}
      onClick={handleClick}
    />
  )
}

const OrderBtnBox = styled.div`
  background: ${props => props.theme.backgrounds.main};
  height: 83px;
  box-shadow: 0px -0.33px 0px #A6A6AA;
  padding: 7px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: ${props => props.theme.backgrounds.tertiary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.4px;
  color: ${props => props.theme.text.buttons};
  cursor: pointer;
  transition: all 0.4s linear;
  
  &:hover {
    filter: brightness(110%);
  }
  
  &:focus, &:active {
    filter: brightness(120%);
  }

  ${OrderBtnBox}.disabled & {
    background: #7E7E82;
    pointer-events: none;
  }
`